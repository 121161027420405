<template>
  <div class="container px-4 pt-10 mx-auto md:px-8">
    <div class="flex items-center">
      <icon-button v-if="isPending" icon="loader" class="animate-spin"/>
      <icon-button v-else icon="arrow-left" @click="$router.go(-1)" />
      <h1 class="flex-1 pb-1 ml-2">Sell Request RS-{{ $route.params.id }}</h1>
    </div>
    <template v-if="!isPending && sell">
      <div class="flex flex-wrap items-center mt-2">
        <div class="py-2 px-4 mr-2 text-sm whitespace-nowrap rounded-full lg:py-2.5 lg:px-6 lg:mr-4 lg:ml-12 lg:text-lg" :class="{ 'text-white': sell.status === 'ar' }" :style="statusStyle">{{ statusToText(sell.status) }}</div>
        <icon-button icon="flag" size="20" class="flex-shrink-0 mr-0.5 lg:hidden" :class="{ 'text-white bg-red-600 hover:bg-red-500 hover:text-white': needInfo }" @click="toggleNeedInfo"/>
        <base-button icon-left="flag" class="hidden mr-2 lg:flex" :class="[ needInfo ? 'text-white bg-red-600 hover:bg-red-500': 'text-black bg-gray-50 hover:bg-gray-100' ]" @click="toggleNeedInfo">{{ needInfo ? 'Flagged' : 'Flag' }} Need Info</base-button>
        <icon-button v-if="!hasNotes" icon="file-plus" size="20" class="flex-shrink-0 mr-0.5 lg:hidden"/>
        <base-button v-if="!hasNotes" icon-left="file-plus" class="hidden mr-2 text-black bg-gray-50 hover:bg-gray-100 lg:flex" @click="addNotes">Add Notes</base-button>
        <sell-merge-modal v-if="sell.status === 'nc'" :sell="sell"/>
        <icon-button @click="deleteItem" icon="trash" size="20" class="flex-shrink-0 mr-0.5 text-red-600 lg:hidden"/>
        <base-button @click="deleteItem" icon-left="trash" class="hidden ml-auto text-red-600 bg-red-50 lg:flex hover:bg-red-100">Delete Request</base-button>
      </div>
      <div class="flex flex-wrap gap-4 mt-6 mb-12">
        <div class="px-6 pt-4 pb-8 bg-white rounded-md shadow lg:px-12 lg:pt-6" :class="[ hasNotes ? 'flex-1': 'w-full' ]">
          <h2 class="font-serif text-2xl lg:text-3xl">Seller Details</h2>
          <div class="flex flex-wrap">
            <div class="w-full" :class="[ hasNotes ? 'lg:w-full' : 'lg:w-1/2' ]">
              <p class="mt-4">
                <span class="block w-32 text-gray-700 md:inline-block">Name</span>
                <span class="text-xl">{{ sell.data.name }}</span>
              </p>
              <p class="mt-4 md:mt-0">
                <span class="block w-32 text-gray-700 md:inline-block">Email</span>
                <span class="text-xl group">{{ sell.data.email }}<sell-email-modal :sell="sell"/></span>
              </p>
              <p class="mt-4 md:mt-0">
                <span class="block w-32 text-gray-700 md:inline-block">Phone</span>
                <span class="text-xl">{{ sell.data.phone }}</span>
              </p>
              <p class="mt-4 md:mt-0">
                <span class="block w-32 text-gray-700 md:inline-block">Postal Code</span>
                <span class="text-xl">{{ sell.data.postal }}</span>
              </p>
            </div>
            <div class="w-full" :class="[ hasNotes ? 'lg:w-full' : 'lg:w-1/2' ]">
              <p class="mt-4">
                <span class="block w-32 text-gray-700 md:inline-block">Newsletter</span>
                <span class="text-xl">{{ sell.data.sms ? 'Subscribed' : 'Not Subscribed' }}</span>
              </p>
              <p class="mt-4 md:mt-0">
                <span class="block w-32 text-gray-700 md:inline-block">Submitted on</span>
                <span class="text-xl">{{ formatDateTime(sell.createdAt) }}</span>
              </p>
              <p class="mt-4 md:mt-0">
                <span class="block w-32 text-gray-700 md:inline-block">Last updated on</span>
                <span class="text-xl">{{ formatDateTime(sell.updatedAt) }}</span>
              </p>
              <feathers-vuex-get
                v-slot="{ item: user }"
                service="users"
                :id="sell.updater"
              >
                <p class="mt-4 md:mt-0">
                  <span class="block w-32 text-gray-700 md:inline-block">Last updated by</span>
                  <span class="text-xl">{{ user ? user.name : 'System' }}</span>
                </p>
              </feathers-vuex-get>
            </div>
          </div>
        </div>
        <div v-if="hasNotes" class="flex flex-col flex-1 px-6 pt-4 pb-8 bg-white rounded-md shadow lg:px-12 lg:pt-6">
          <div class="flex items-center mb-2">
            <h2 class="font-serif text-2xl lg:text-3xl">Notes</h2>
            <base-button icon-left="save" @click="saveNotes" class="px-2 py-1 ml-auto text-gray-700 bg-gray-50 hover:bg-gray-100">Save</base-button>
            <icon-button icon="trash" size="18" class="ml-1 text-red-600" @click="removeNotes"/>
          </div>
          <textarea v-model="notes" class="flex-auto w-full px-0 py-2 text-lg placeholder-gray-300 border-0 border-t border-gray-300 resize-none h-52 focus:ring-0 focus:border-gray-300" placeholder="Write some notes..."></textarea>
        </div>
        <div class="flex flex-wrap w-full overflow-hidden bg-white rounded-md shadow">
          <div class="w-full px-6 pt-4 pb-8 lg:px-12 lg:pt-6" :class="[ logistic === 'Unset' ? 'lg:w-full' : 'lg:w-1/2' ]">
            <template v-if="logistic === 'Unset'">
              <h2 class="font-serif text-2xl lg:text-3xl">Choose Logistics</h2>
              <p>Change logistic to pickup/drop-off to continue.</p>
              <div class="mt-6">
                <sell-logistic-modal logistic="Pickup" :sell="sell" class="inline-block mb-2 mr-2"/>
                <sell-logistic-modal logistic="Dropoff" :sell="sell" class="inline-block mb-2 mr-2"/>
                <template v-if="sell.status === 'nc' && sell.data.customProducts.length === 0">
                  <p class="mt-2 mb-1 text-sm text-gray-700">Scheduling Link</p>
                  <div class="inline-flex px-3 py-2 text-blue-700 border border-blue-500 rounded-md bg-blue-50">
                    <icon name="link" size="16" class="flex-grow-0 flex-shrink-0 my-1"/>
                    <p class="flex-1 ml-2 break-all select-all">{{ `https://sell.rebelstork.com/schedule?ref=${sell.id}&key=${sell.key}` }}</p>
                  </div>
                </template>
              </div>
            </template>
            <template v-if="logistic === 'Pickup'">
              <div class="flex">
                <h2 class="flex-1 font-serif text-2xl lg:text-3xl">Pickup Logistics</h2>
                <sell-logistic-modal v-if="sell.status === 'nc'" logistic="Dropoff" :sell="sell">
                  <template #default="{ open }">
                    <icon-button icon="repeat" size="18" title="Change Logistic" @click="open"/>
                  </template>
                </sell-logistic-modal>
              </div>
              <p>{{ logisticText }}</p>
              <p class="mt-4 font-bold">Location:</p>
              <p>{{ sell.data.address || 'No location set' }}</p>
              <p class="mt-4 font-bold">Pickup Date:</p>
              <div class="flex">
                <p>{{ logisticDate }}</p>
                <sell-time-modal v-if="sell.status === 'nc'" :sell="sell"/>
              </div>
              <div class="flex flex-shrink-0 mt-8">
                <input
                  class="w-16 px-1 mr-2 leading-10 text-center placeholder-gray-300 bg-transparent border border-gray-300 rounded tabular-nums lg:px-3 lg:text-lg lg:w-24 focus:outline-none"
                  placeholder="09:00"
                  v-model="pickupStart"
                />
                <input
                  class="w-16 px-1 mr-2 leading-10 text-center placeholder-gray-300 bg-transparent border border-gray-300 rounded tabular-nums lg:px-3 lg:text-lg lg:w-24 focus:outline-none"
                  placeholder="13:00"
                  v-model="pickupEnd"
                />
                <base-button class="text-base" @click="setPickupTimes">Set Pickup Time</base-button>
              </div>
              <div class="mt-2">
                <div v-if="!pickupStart && !pickupEnd" class="inline-flex items-center px-4 py-2 text-red-600 rounded-md bg-red-50">
                  <icon name="alert-triangle" size="18"/><span class="ml-2">Confirm Pickup Time Range</span>
                </div>
                <div v-else-if="showTimeAlert" class="inline-flex items-center px-4 py-2 text-yellow-700 bg-yellow-100 rounded-md">
                  <icon name="alert-triangle" size="18"/><span class="ml-2">Seller will not be notified that pickup time is changing.</span>
                </div>
              </div>
            </template>
            <template v-if="logistic === 'Drop-off'">
              <div class="flex">
                <h2 class="flex-1 font-serif text-2xl lg:text-3xl">Drop-off Logistics</h2>
                <sell-logistic-modal v-if="sell.status === 'nc'" logistic="Pickup" :sell="sell">
                  <template #default="{ open }">
                    <icon-button icon="repeat" size="18" title="Change Logistic" @click="open"/>
                  </template>
                </sell-logistic-modal>
              </div>
              <p class="mt-4">{{ logisticText }}</p>
              <p class="mt-4 font-bold">Location:</p>
              <p v-if="location">{{ location.id }} {{ location.name }}<br/>{{ location.snum }} {{ location.street }}, {{ location.city }}</p>
              <p v-else>No location set</p>
              <p class="mt-4 font-bold">Pickup Date:</p>
              <div class="flex">
                <p>{{ logisticDate }}</p>
                <sell-time-modal v-if="sell.status === 'nc'" :sell="sell"/>
              </div>
              <p class="mt-6">To change times for drop-off, change the hours in the <router-link to="/pickup" class="underline">drop-off locations editor</router-link>.</p>
            </template>
          </div>
          <sell-detail-map v-if="coordinates" :coordinates="coordinates"/>
        </div>
        <div class="w-full px-6 pt-4 pb-8 bg-white rounded-md shadow lg:pl-12 lg:pt-6">
          <div class="flex flex-wrap">
            <h2 class="w-full mb-2 mr-auto font-serif text-2xl whitespace-nowrap lg:w-auto lg:leading-10 lg:text-3xl">{{ sell.data.products.length + sell.data.customProducts.length }} Items for {{ logistic }}</h2>
            <div v-if="sell.data.customProducts.length" class="inline-flex items-center px-4 py-2 mb-2 mr-2 text-red-600 rounded-md bg-red-50">
              <icon name="alert-triangle" size="18"/><span class="ml-2">Create a Quote for Custom Item(s)</span>
            </div>
            <sell-product-modal v-if="sell.status === 'nc'" :sell="sell"/>
          </div>
          <div class="flex flex-wrap mt-6">
            <div v-for="(p, index) in sell.data.products" :key="`${p.productId}-${index}`" class="w-full py-4 pl-6 pr-4 mb-4 shadow bg-green-50 rounded-2xl md:mr-4 md:w-80">
              <feathers-vuex-get
                v-slot="{ item: product }"
                service="products"
                :id="p.productId"
              >
                <div v-if="product">
                  <h3 class="text-xl font-bold">{{ product.name }}</h3>
                  <p><span class="inline-block w-16 text-sm text-gray-700">Brand</span> {{ product.brand.name }}</p>
                  <p><span class="inline-block w-16 text-sm text-gray-700">Age</span> {{ ageToText(p.age) }}</p>
                  <p><span class="inline-block w-16 text-sm text-gray-700">Condition</span> {{ conditionToText(p.condition) }}</p>
                  <p class="flex items-center mt-2">
                    <input type="checkbox" class="cursor-default checkbox" v-model="p.petFree" disabled/>
                    <span class="ml-2 mr-4">
                      Pet Free
                    </span>
                    <input type="checkbox" class="cursor-default checkbox" v-model="p.smokeFree" disabled/>
                    <span class="ml-2 mr-4">
                      Smoke Free
                    </span>
                  </p>
                  <p class="flex items-center mt-1">
                    <input type="checkbox" class="text-blue-500 cursor-default checkbox" v-model="p.cleaningFee" disabled/>
                    <span class="ml-2 mr-4">
                      Cleaning Fee
                    </span>
                  </p>
                  <div class="flex items-end mt-3">
                    <div class="flex-1">
                      <p class="text-sm text-gray-700">Quote</p>
                      <p class="text-2xl">{{ product.info.currency || 'CAD' }} {{ p.estimate }}</p>
                    </div>
                    <sell-product-modal :sell="sell" :product="p" isEditing>
                      <template #default="{ open }">
                        <icon-button icon="edit" size="20" @click="open"/>
                      </template>
                    </sell-product-modal>
                    <icon-button icon="trash" size="20" @click="removeProduct(p)"/>
                    <sell-interface-modal v-if="p.interfaceId" :sell="sell" :product="p"></sell-interface-modal>
                  </div>
                </div>
              </feathers-vuex-get>
            </div>
            <div v-for="(p, index) in sell.data.customProducts" :key="`${p.product}-${index}`" class="w-full py-4 pl-6 pr-4 mb-4 bg-yellow-100 shadow rounded-2xl md:mr-4 md:w-80">
              <h3 class="text-xl font-bold">{{ p.product }}</h3>
              <p><span class="inline-block w-16 text-sm text-gray-700">Brand</span> {{ p.brand }}</p>
              <p><span class="inline-block w-16 text-sm text-gray-700">Age</span> {{ ageToText(p.age) }}</p>
              <p><span class="inline-block w-16 text-sm text-gray-700">Condition</span> {{ conditionToText(p.condition) }}</p>
              <p class="flex items-center mt-2">
                <input type="checkbox" class="checkbox" v-model="p.petFree" disabled/>
                <span class="ml-2 mr-4">
                  Pet Free
                </span>
                <input type="checkbox" class="checkbox" v-model="p.smokeFree" disabled/>
                <span class="ml-2 mr-4">
                  Smoke Free
                </span>
              </p>
              <div class="flex items-end mt-4">
                <base-button class="mr-auto border-transparent bg-opacity-60 hover:bg-opacity-70" icon-right="external-link" @click="openModal(p)" :disabled="isStaff">Create Quote</base-button>
                <icon-button icon="trash" size="20" @click="removeCustomProduct(p)"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isStaff && sell.data.customProducts.length > 0" class="inline-flex items-center w-full px-4 py-2 mt-6 text-yellow-700 bg-yellow-100 rounded-md">
          <icon name="alert-triangle" size="18"/><span class="ml-2">An administrator will have to process this order because there are custom items in the request.</span>
        </div>
        <div v-else-if="actionButtonText" class="mt-6">
          <base-button :icon-left="loading ? 'loader' : 'fast-forward'" class="font-bold bg-blue-500" @click="onClickMove" :disabled="actionButtonDisabled">{{ loading ? 'Loading...' : actionButtonText }}</base-button>
        </div>
      </div>
    </template>
    <sell-quote-modal
      v-if="showQuoteModal"
      :sell="sell"
      :product="quoteProduct"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { models, useGet, FeathersVuexGet } from 'feathers-vuex'
import { format, getDay, parse, parseISO, subDays } from 'date-fns'
import { ageToText, conditionToText, statusToColor, statusToText } from '../utils'
import SellQuoteModal from '@/components/SellQuoteModal'
import SellDetailMap from '@/components/SellDetailMap'
import SellLogisticModal from '@/components/SellLogisticModal'
import SellProductModal from '@/components/SellProductModal'
import SellTimeModal from '@/components/SellTimeModal'
import SellMergeModal from '@/components/SellMergeModal'
import SellInterfaceModal from '@/components/SellInterfaceModal'
import SellEmailModal from '@/components/SellEmailModal'

export default {
  name: 'SellDetail',
  components: {
    FeathersVuexGet,
    SellQuoteModal,
    SellDetailMap,
    SellLogisticModal,
    SellProductModal,
    SellTimeModal,
    SellMergeModal,
    SellInterfaceModal,
    SellEmailModal
  },
  setup(_, { root }) {
    const { Sell, PickupLocation } = models.api
    const { item: sell, isPending, hasLoaded } = useGet({ model: Sell, id: root.$route.params.id })

    const locationQueryWhen = computed(() => {
      return sell.value && sell.value.data.logistics.type === 'Dropoff'
    })

    const locationId = computed(() => {
      return sell.value ? sell.value.data.logistics.dropoffLocationId : null
    })

    const { item: location } = useGet({
      model: PickupLocation,
      id: locationId,
      queryWhen: locationQueryWhen
    })

    const coordinates = computed(() => {
      if (!sell.value ||
        (sell.value.data.logistics.type === 'Dropoff' && !location.value) ||
        sell.value.data.logistics.type === 'Unset') {
        return null
      }
      return sell.value.data.logistics.type === 'Pickup' ? sell.value.data.coordinates : location.value.ancillary.coordinates
    })

    return {
      sell,
      location,
      coordinates,
      isPending,
      hasLoaded
    }
  },
  data() {
    return {
      loading: false,
      showQuoteModal: false,
      quoteProduct: null,
      pickupStart: null,
      pickupEnd: null
    }
  },
  watch: {
    hasLoaded(val) {
      if (val && this.sell.data.logistics.pickupTimes?.start && this.sell.data.logistics.pickupTimes?.end) {
        this.pickupStart = this.sell.data.logistics.pickupTimes.start
        this.pickupEnd = this.sell.data.logistics.pickupTimes.end
      }
    }
  },
  computed: {
    isStaff() {
      return this.$store.getters.isStaff
    },
    statusToText() {
      return (status) => statusToText[status]
    },
    statusStyle() {
      const color = statusToColor[this.sell.status]
      return 'background-color: ' + color
    },
    ageToText() {
      return (n) => ageToText[n]
    },
    conditionToText() {
      return (n) => conditionToText[n]
    },
    logistic() {
      return this.sell?.data?.logistics.type !== 'Dropoff' ? this.sell.data.logistics.type : 'Drop-off'
    },
    logisticText() {
      const { data } = this.sell
      if (!data || !this.location) { return }
      return {
        Pickup: {
          nc: `${data.name} has agreed to have their items picked up on ${this.formatDate(data.logistics.date)}.`,
          ap: `${data.name} has agreed to have their items picked up on ${this.formatDate(data.logistics.date)}.`,
          it: 'Item is currently in transit.  You cannot modify logistics data until it has arrived in the warehouse.',
          ar: `Item was picked up from ${data.address} on ${data.logistics.date}.`
        },
        Dropoff: {
          nc: `${data.name} has agreed to drop-off their items between ${this.getHours(this.location.ancillary.hours)} on ${this.formatDateMinus(data.logistics.date)}.`,
          ad: `${data.name} has agreed to drop-off their items between ${this.getHours(this.location.ancillary.hours)} on ${this.formatDateMinus(data.logistics.date)}.`,
          do: `${data.name} has dropped off their item at ${this.location.name}'`,
          it: 'Item is currently in transit.  You cannot modify logistics data until it has arrived in the warehouse.',
          ar: `Item was dropped off at ${this.location.name} on ${this.formatDate(data.logistics.date)}.`
        }
      }[this.sell.data.logistics.type][this.sell.status] ?? ''
    },
    logisticDate() {
      return this.sell.data.logistics.date ? format(parseISO(this.sell.data.logistics.date), 'd MMM yyyy') : 'No Date'
    },
    nextStatus() {
      const flow = {
        Pickup: ['nc', 'ap', 'it', 'ar'],
        Dropoff: ['nc', 'ad', 'do', 'it', 'ar']
      }[this.sell.data.logistics.type]
      const index = flow.findIndex(i => i === this.sell.status)
      return flow[index + 1]
    },
    actionButtonText() {
      if (this.sell.status === 'ar') return
      if (this.sell.status === 'nc' && this.sell.data.logistics.type === 'Unset') return 'Move to Pickup/Dropoff'
      return 'Move to ' + this.statusToText(this.nextStatus)
    },
    actionButtonDisabled() {
      return (this.sell.status === 'nc' && this.sell.data.customProducts.length > 0) || this.sell.data.logistics.type === 'Unset'
    },
    needInfo() {
      return this.sell.data.needInfo ?? false
    },
    hasNotes() {
      return typeof this.sell.data.notes === 'string'
    },
    notes: {
      get() {
        return this.sell.data.notes ?? ''
      },
      set(value) {
        this.sell.data.notes = value
      }
    },
    showTimeAlert() {
      return this.sell.status !== 'nc' && (
        (this.pickupStart && this.pickupStart !== this.sell.data.logistics.pickupTimes?.start) ||
        (this.pickupEnd && this.pickupEnd !== this.sell.data.logistics.pickupTimes?.end)
      )
    }
  },
  methods: {
    getHours(hours) {
      const day = getDay(parseISO(this.sell.data.logistics.date))
      const h = hours.find(h => h.day === day)
      const f = (h) => format(parse(h, 'HH:mm', new Date()), 'haaa')
      return `${f(h.open)} and ${f(h.close)}`
    },
    formatDate(str) {
      return format(parseISO(str), 'dd MMM yyyy')
    },
    formatDateMinus(str) {
      return format(subDays(parseISO(str), 1), 'dd MMM yyyy')
    },
    formatDateTime(str) {
      return format(parseISO(str), 'dd MMM yyyy, hh:mm a')
    },
    async onClickMove() {
      if (this.actionButtonDisabled) { return }
      this.loading = true
      if (this.sell.data.logistics.type === 'Pickup' && !this.pickupStart && !this.pickupEnd) {
        this.pickupStart = '09:00'
        this.pickupEnd = '13:00'
        await this.setPickupTimes()
      }
      this.sell.patch({ data: { status: this.nextStatus } }).then(() => {
        this.$store.commit('notify', { type: 'success', title: 'Success updating status' })
        this.loading = false
      }).catch(err => {
        this.$store.commit('notify', { type: 'alert', title: `Error: ${err.message}` })
        this.loading = false
      })
    },
    toggleNeedInfo() {
      this.sell.data.needInfo = !this.needInfo
      this.sell.save()
    },
    addNotes() {
      this.sell.data.notes = ''
      this.sell.save()
    },
    saveNotes() {
      this.sell.save().then(() => {
        this.$store.commit('notify', { type: 'success', title: 'Notes saved successfully' })
      }).catch(() => {
        this.$store.commit('notify', { type: 'alert', title: 'Failed saving notes' })
      })
    },
    removeNotes() {
      if (confirm('Are you sure you want to remove notes?')) {
        this.$delete(this.sell.data, 'notes')
        this.sell.save()
      }
    },
    async setPickupTimes() {
      if (!this.pickupStart || !this.pickupEnd) {
        this.$store.commit('notify', { type: 'alert', title: 'Pickup times cannot be empty' })
        return
      }
      this.sell.data.logistics = {
        ...this.sell.data.logistics,
        pickupTimes: {
          start: this.pickupStart,
          end: this.pickupEnd
        }
      }
      this.sell.save().then(() => {
        this.$store.commit('notify', { type: 'success', title: 'Success saving Pickup times' })
      })
    },
    openModal(product) {
      this.quoteProduct = product
      this.showQuoteModal = true
    },
    closeModal() {
      this.showQuoteModal = false
      this.quoteProduct = null
    },
    removeProduct(product) {
      if (confirm('Remove product?')) {
        this.sell.data.products.splice(this.sell.data.products.indexOf(product), 1)
        this.sell.save(() => {
          this.$store.commit('notify', { type: 'success', title: 'Success removing product' })
        })
      }
    },
    removeCustomProduct(custom) {
      if (confirm('Remove custom product?')) {
        this.sell.data.customProducts.splice(this.sell.data.customProducts.indexOf(custom), 1)
        this.sell.save(() => {
          this.$store.commit('notify', { type: 'success', title: 'Success removing custom product' })
        })
      }
    },
    deleteItem() {
      if (confirm('Delete sell request?')) {
        this.sell.remove().then(() => this.$router.push('/sell'))
      }
    }
  }
}
</script>
