<template>
  <div id="map" class="flex self-stretch w-full h-96 bg-blue-100 lg:w-1/2">
    <icon v-if="loading" name="loader" class="m-auto animate-spin"/>
  </div>
</template>

<script>
import { mapboxgl } from '@/services/mapbox'

export default {
  name: 'SellDetailMap',
  props: {
    coordinates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      map: null,
      marker: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.createMap()
    })
  },
  methods: {
    async createMap() {
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: this.coordinates,
        zoom: 16
      })

      this.map.on('load', () => {
        this.marker = new mapboxgl.Marker({ color: '#D80739' })
          .setLngLat(this.coordinates)
          .addTo(this.map)

        this.loading = false
      })
    }
  }
}
</script>
