<template>
  <div class="block relative my-6">
    <label class="text-gray-700">Product Finder</label>
    <div
      class="flex items-center mt-1 text-xl rounded-md border shadow-sm focus-within:border-gray-700 focus-within:ring focus-within:ring-gray-100" :class="[ disabled ? 'border-gray-300' : 'border-gray-400' ]"
    >
      <div v-if="selected" class="flex flex-1 items-center pl-3 h-12 bg-transparent border-none select-none focus:ring-0 focus:outline-none">
        <div class="flex-1">
          <p class="text-base leading-tight">{{ selected.name }}</p>
          <p class="text-sm font-bold leading-none text-r-green">{{ selected.brand.name }}</p>
        </div>
        <div v-if="selected.info && selected.info.currency === 'USD'" class="px-2 text-sm font-semibold leading-6 text-green-600 bg-green-100 bg-opacity-50 rounded">USD</div>
      </div>
      <input
        v-else
        ref="inputRef"
        class="flex-1 py-2 px-3 leading-8 bg-transparent border-none focus:ring-0 focus:outline-none"
        v-model="search"
        @focus="showDropdown = true"
        @blur="showDropdown = false"
        @keydown="handleKeydown"
        :disabled="disabled"
        name="Product"
      />
      <template v-if="!disabled">
        <icon-button v-if="search.length || selected" icon="x" class="flex-shrink-0 mx-0.5 text-gray-500" size="20" @click="clear"/>
        <icon-button v-else icon="search" class="flex-shrink-0 mx-0.5 text-gray-500" size="20" @click="onClickSearch"/>
      </template>
    </div>
    <div v-show="showDropdown && (search.length || showSuggested)" class="absolute left-0 top-16 z-10 my-4 w-full bg-white rounded-md border border-gray-200 shadow-lg outline-none">
      <div class="overflow-y-auto py-2 max-h-64 wrapper" ref="wrapperRef">
        <div
          class="flex items-center px-4 h-12 cursor-pointer option hover:bg-r-green-200 hover:bg-opacity-20"
          :class="{ 'bg-r-green-200 bg-opacity-20': index === selectedIndex }"
          :aria-selected="index === selectedIndex"
          :key="product.id"
          v-for="(product, index) in products"
          @mousedown="onClickSelect(product)"
        >
          <div class="flex-1">
            <p class="text-base leading-tight">{{ product.name }}</p>
            <p class="text-sm font-bold leading-none text-r-green">{{ product.brand ? product.brand.name : 'No Brand' }}</p>
          </div>
          <span v-if="showSuggested" class="text-sm leading-10 text-r-green">Suggested</span>
          <div v-if="product.info && product.info.currency === 'USD'" class="px-2 text-sm font-semibold leading-6 text-green-600 bg-green-100 bg-opacity-50 rounded">USD</div>
        </div>
        <div
          v-if="search.length && !products.length"
          class="flex items-center px-4 h-12 text-gray-400 cursor-pointer"
        >
          <span>No Result</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, nextTick } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'

export default {
  name: 'ProductPicker',
  props: {
    value: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    suggestion: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const { Product } = models.api

    const inputRef = ref(null)
    const showDropdown = ref(false)
    const search = ref('')

    const fetchParams = computed(() => {
      let query = {}
      if (search.value.length) {
        query = {
          name: { $iLike: '%' + search.value.trim() + '%' },
          role: 'a',
          $sort: { name: 1 }
        }
      } else if (props.suggestion) {
        query = {
          name: { $iLike: '%' + props.suggestion + '%' },
          role: 'a'
        }
      }
      if (props.value) {
        Object.assign(query, { id: props.value })
      }
      return { query, qid: 'productsFetch' }
    })

    const params = computed(() => {
      let query = {}
      if (search.value.length) {
        query = {
          name: { $regex: search.value.trim(), $options: 'i' },
          role: 'a'
        }
      } else if (props.suggestion) {
        query = {
          name: { $regex: props.suggestion, $options: 'i' },
          role: 'a'
        }
      }
      if (props.value) {
        Object.assign(query, { id: props.value })
      }
      return { query, qid: 'productsParams' }
    })

    const { items: products, latestQuery, isPending } = useFind({ model: Product, params, fetchParams, immediate: true })

    const selected = computed(() => {
      if (props.value && products.value.length) {
        return products.value[0]
      }
      return null
    })

    const showSuggested = computed(() => {
      return props.suggestion && products.value.length === 1 && !search.value
    })

    const onClickSelect = (product) => {
      emit('input', product.id)
      showDropdown.value = false
    }

    const onClickSearch = () => {
      inputRef.value.focus()
    }

    const clear = () => {
      search.value = ''
      selectedIndex.value = -1
      emit('input', null)
    }

    watch(() => props.value, (val) => {
      if (!val) { clear() }
    })

    const wrapperRef = ref(null)
    const selectedIndex = ref(-1)
    const handleKeydown = (event) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault()
        if (products.value.length > 0 && selectedIndex.value < products.value.length - 1) {
          selectedIndex.value++
          scrollToSelected()
        }
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault()
        if (selectedIndex.value > -1) {
          selectedIndex.value--
          scrollToSelected()
        }
      }

      if (event.key === 'Enter') {
        event.preventDefault()
        if (selectedIndex.value > -1) {
          emit('input', products.value[selectedIndex.value].id)
          showDropdown.value = false
        }
      }

      if (event.key === 'Escape') {
        showDropdown.value = false
        selectedIndex.value = -1
        search.value = ''
        inputRef.value.blur()
      }
    }

    const scrollToSelected = () => {
      nextTick(() => {
        if (wrapperRef.value) {
          wrapperRef.value.scrollTop = (selectedIndex.value * 48) - (48 * 2)
        }
      })
    }

    return {
      selected,
      selectedIndex,
      showDropdown,
      search,
      inputRef,
      products,
      latestQuery,
      isPending,
      onClickSelect,
      onClickSearch,
      showSuggested,
      handleKeydown,
      wrapperRef,
      clear,
      props
    }
  }
}
</script>
