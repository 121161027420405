<template>
  <modal @close="$emit('close')">
    <template #default="{ close }">
      <div class="py-3 px-5 lg:px-12 lg:py-10">
        <h1>Create Quote</h1>
        <h2 class="mt-2 text-lg font-bold">Here's what the seller entered:</h2>
        <div class="mt-2 mb-4 text-lg">
          <p><span class="inline-block w-28 text-base text-gray-700">Product Name</span> {{ customProduct.product }}</p>
          <p><span class="inline-block w-28 text-base text-gray-700">Brand</span> {{ customProduct.brand }}</p>
          <p><span class="inline-block w-28 text-base text-gray-700">Condition</span> {{ conditionToText(customProduct.condition) }}</p>
          <p><span class="inline-block w-28 text-base text-gray-700">Age</span> {{ ageToText(customProduct.age) }}</p>
          <p><span class="inline-block w-28 text-base text-gray-700">Pet Free</span> {{ customProduct.petFree ? 'Yes' : 'No' }}</p>
          <p><span class="inline-block w-28 text-base text-gray-700">Smoke Free</span> {{ customProduct.smokeFree ? 'Yes' : 'No' }}</p>
        </div>
        <hr/>
        <h2 class="mt-4 text-lg font-bold">Find product from the existing database</h2>
        <product-picker v-model="productId" :suggestion="customProduct.product"/>
        <hr/>
        <div v-show="!productId" class="my-4">
          <div class="flex items-center">
            <h2 class="flex-1 text-lg font-bold">Or add new product to the database</h2>
            <icon-button :icon="showProductForm ? 'chevron-up' : 'chevron-down'" class="flex-shrink-0" @click="showProductForm = !showProductForm"/>
          </div>
          <div v-show="showProductForm">
            <base-form-input label="Product Name" v-model="productForm.name" :error="!productForm.name"/>
            <brand-picker required v-model="productForm.brandId" :suggestion="customProduct.brand"/>
            <base-form-select label="Category" v-model="productCategory" :error="!productCategory">
              <option value="" disabled selected hidden>Pick Category</option>
              <option v-for="(c, i) in categories" :key="i" :value="c.title">{{ c.title }}</option>
            </base-form-select>
            <base-form-input label="MSRP" icon="dollar-sign" v-model="productForm.value" :error="!productForm.value > 0"/>
          </div>
        </div>
        <hr v-show="!productId"/>
        <base-button class="py-2 mt-6 w-full font-bold bg-blue-500 md:py-3" @click="onClickCreate(close)">
          {{ loading ? 'Loading...' : productId ? 'Create Quote' : 'Create Product + Quote'}}
        </base-button>
        <base-button outline @click="close" class="mt-2 w-full">Cancel</base-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { models } from 'feathers-vuex'
import { host } from '../api/client'
import { ageToText, categories, conditionToText } from '../utils'
import Modal from '@/components/Modal'
import BrandPicker from '@/components/BrandPicker'
import ProductPicker from '@/components/ProductPicker'

export default {
  components: {
    Modal,
    BrandPicker,
    ProductPicker
  },
  props: {
    sell: Object,
    product: Object
  },
  data() {
    return {
      loading: false,
      showProductForm: false,
      productForm: {
        name: '',
        brandId: null,
        category: 0,
        value: null,
        role: 'a',
        info: {}
      },
      productId: null
    }
  },
  computed: {
    customProduct() {
      return this.product
    },
    categories() {
      return categories
    },
    productCategory: {
      get() {
        return this.productForm.info?.categoryName ?? ''
      },
      set(val) {
        const item = this.categories.find(c => c.title === val)
        this.productForm.category = item.value
        this.productForm.info = {
          ...this.productForm.info,
          categoryName: item.title
        }
      }
    }
  },
  methods: {
    ageToText(n) {
      return ageToText[n]
    },
    conditionToText(n) {
      return conditionToText[n]
    },
    async onClickCreate(close) {
      this.loading = true
      const { Product, Sell } = models.api
      let category
      if (!this.productId) {
        const product = new Product(this.productForm)
        const createdProduct = await product.create()
        this.productId = createdProduct.id
        category = this.productForm.category
        this.$store.commit('notify', { title: 'Success creating product', type: 'success' })
      } else {
        const product = await Product.get(this.productId)
        category = product.category
      }
      // get estimates
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          product: this.productId,
          age: this.customProduct.age,
          condition: this.customProduct.condition,
          category: category
        })
      }
      const response = await fetch(`${host}/intel/estimator`, options)
      const data = await response.json()
      const estimate = data.estimate
      if (!estimate) {
        this.loading = false
        return
      }
      // move customProducts to products
      const sell = new Sell(this.sell)
      sell.data = {
        ...sell.data,
        products: [
          ...sell.data.products,
          {
            productId: this.productId,
            age: this.customProduct.age,
            condition: this.customProduct.condition,
            category: this.productForm.category,
            estimate,
            petFree: this.customProduct.petFree,
            smokeFree: this.customProduct.smokeFree
          }
        ],
        customProducts: sell.data.customProducts
          .filter(c => c.brand !== this.customProduct.brand &&
            c.product !== this.customProduct.product)
      }
      sell.patch().then(() => {
        this.loading = false
        this.$store.commit('notify', { title: 'Success creating quote', type: 'success' })
        close()
      })
    }
  }
}
</script>
