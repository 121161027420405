<template>
  <span>
    <icon-button
      icon="link"
      size="20"
      @click="open"
      :class="{ 'text-opacity-50': !shopifyId }"
      :title="shopifyId ? 'Connected to Shopify Product' : 'Connect to Shopify Product'"
    />
    <modal v-if="isOpen" @close="isOpen = false">
      <template #default="{ close }">
        <div class="py-3 px-5 lg:px-12 lg:py-10">
          <h1>Connect Sell Request to Shopify Product</h1>
          <p class="mt-4 text-lg">Once an item is listed on the store front, it needs to be linked so we can read the status of the item in Shopify to tell the user when things are sold.  Simply paste the link from the item in the Shopify admin in the field below and hit ‘Done’.</p>
          <base-form-input icon="link" label="Paste the link from the Shopify admin here" v-model="shopifyUrl"/>
          <base-form-input label="Shopify Product ID" v-model="shopifyId" disabled/>
          <p v-if="isConnected" class="mb-6 -mt-2 text-lg">
            <a :href="`https://rebelstork.myshopify.com/admin/products/${shopifyId}`" target="_blank" class="text-blue-600">
              <icon name="external-link" size="16" class="inline-block mr-2 mb-px"/>
              <span class="underline">Connected to Shopify product</span>
            </a>
          </p>
          <hr/>
          <div class="flex mt-6">
            <base-button outline @click="close" class="mr-2 w-48">Cancel</base-button>
            <base-button
              class="w-full bg-blue-500"
              @click="save(close)"
              :disabled="!shopifyId"
            >
              {{ loading ? 'Loading...' : 'Done' }}
            </base-button>
          </div>
        </div>
      </template>
    </modal>
  </span>
</template>

<script>
import feathersClient from '../api/client'
import Modal from '@/components/Modal'

export default {
  name: 'SellInterfaceModal',
  props: {
    sell: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      default: null
    }
  },
  components: {
    Modal
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      shopifyUrl: null,
      shopifyId: null,
      isConnected: false
    }
  },
  watch: {
    shopifyUrl(val) {
      if (val) {
        const productId = val.split('/').pop()
        if (productId.match(/^[0-9]+$/)) {
          this.shopifyId = productId
        }
      }
    }
  },
  mounted() {
    if (this.product.interfaceId) {
      feathersClient.service('sell/interface').get(this.product.interfaceId)
        .then((res) => {
          if (res.shopifyRef) {
            this.shopifyId = res.shopifyRef
            this.isConnected = true
          }
        })
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    save(close) {
      if (!this.shopifyId.match(/^[0-9]+$/)) {
        return
      }
      this.loading = true
      feathersClient.service('sell/interface').patch(this.product.interfaceId, { shopifyRef: this.shopifyId })
        .then(() => {
          this.loading = false
          close()
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }
}
</script>
