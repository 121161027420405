<template>
  <span>
    <icon name="edit-3" size="16" @click.native="open" class="inline-block ml-2 opacity-25 cursor-pointer group-hover:opacity-75"/>
    <modal v-if="isOpen" @close="isOpen = false">
      <template #default="{ close }">
        <div class="py-3 px-5 lg:px-12 lg:py-10">
          <h1>Change Email Address</h1>
          <base-form-input label="Current Email Address" :value="sell.data.email" disabled/>
          <base-form-input label="New Email Address" v-model="email"/>
          <hr/>
          <div class="flex mt-6">
            <base-button outline @click="close" class="mr-2 w-48">Cancel</base-button>
            <base-button
              class="w-full bg-blue-500"
              @click="save(close)"
              :disabled="!email"
            >
              {{ loading ? 'Loading...' : 'Change Email' }}
            </base-button>
          </div>
        </div>
      </template>
    </modal>
  </span>
</template>

<script>
import { models } from 'feathers-vuex'
import Modal from '@/components/Modal'

export default {
  name: 'SellProductModal',
  props: {
    sell: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      email: null
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    save(close) {
      if (!this.email) {
        return
      }
      this.loading = true
      const { Sell } = models.api
      const sell = new Sell(this.sell).clone()
      sell.data.email = this.email
      sell.patch().then(() => {
        this.loading = false
        this.email = null
        close()
      }).catch((err) => {
        console.log('err', err)
      })
    }
  }
}
</script>
