<template>
  <span>
    <slot :open="open">
      <base-button @click="open">Change to {{ logistic }}</base-button>
    </slot>
    <modal v-if="isOpen" @close="isOpen = false">
      <template #default="{ close }">
        <div class="px-5 py-3 lg:px-12 lg:py-10">
          <h1>Change Logistic to {{ logistic }}</h1>
          <template v-if="logistic === 'Pickup'">
            <h2 class="mt-3 text-lg font-bold">
              1. Find the user's address
            </h2>
            <p class="text-gray-600" v-if="sell.data.address" title="User's input">{{ sell.data.address }}</p>
            <div class="flex my-4 h-96">
              <div id="map" class="flex w-full h-full bg-blue-100">
                <icon v-if="loading" name="loader" class="m-auto animate-spin"/>
              </div>
            </div>
            <hr/>
            <h2 class="my-3 text-lg font-bold" :class="{ 'text-gray-300': postalCode.length === 0 }">
              2. Pick schedule in this service area
            </h2>
            <div class="my-3" v-if="isPostalPending">
              <icon name="loader" class="m-auto animate-spin"/>
            </div>
            <div v-else-if="postal" class="my-3">
              <p class="font-bold text-gray-600">{{ postal.id }} {{ postal.city }}</p>
              <div class="my-2">
                <div
                  class="px-3 py-1 mb-1 border border-gray-200 rounded-md cursor-pointer hover:border-gray-400"
                  :class="{ 'bg-r-green-200 bg-opacity-20 border-green-600': s === selectedDate }"
                  v-for="(s, index) in filterSchedules(postal.ancillary.schedule)"
                  :key="index"
                  @click="selectedDate = s"
                >
                  <h3>{{ formatDate(s) }}</h3>
                </div>
              </div>
            </div>
            <hr/>
          </template>
          <template v-if="logistic === 'Dropoff'">
            <h2 class="my-3 text-lg font-bold">
              1. Pick a drop-off location
            </h2>
            <template v-if="!selectedLocation">
              <base-form-input tight label="Search Drop-off Location" v-model="search"/>
              <div class="h-56 my-6">
                <div
                  class="h-12 px-3 py-1 mb-2 border border-gray-200 rounded-md cursor-pointer hover:border-gray-400"
                  v-for="item in locations"
                  :key="item.id"
                  @click="selectedLocation = item"
                >
                  <h3 class="font-bold leading-tight">{{ item.id }} {{ item.name }}</h3>
                  <p class="text-sm">{{ item.snum }} {{ item.street }}, {{ item.city }}, {{ item.province }}</p>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="text-gray-800">Selected Drop-off Location</p>
              <div class="relative h-12 px-3 py-1 mt-1 mb-6 border rounded-md bg-opacity-20 border-r-green-200 bg-r-green-200">
                <h3 class="font-bold leading-tight">{{ selectedLocation.id }} {{ selectedLocation.name }}</h3>
                <p class="text-sm">{{ selectedLocation.snum }} {{ selectedLocation.street }}, {{ selectedLocation.city }}, {{ selectedLocation.province }}</p>
                <icon-button icon="x" size="18" @click="selectedLocation = null" class="absolute w-8 h-8 top-px right-px"/>
              </div>
            </template>
            <hr/>
            <h2 class="my-3 text-lg font-bold" :class="{ 'text-gray-300': !selectedLocation }">
              2. Pick a drop-off schedule for user
            </h2>
            <div class="my-2">
              <div
                class="px-3 py-1 mb-1 border border-gray-200 rounded-md cursor-pointer hover:border-gray-400"
                :class="{ 'bg-r-green-200 bg-opacity-20 border-r-green-200': s === selectedDate }"
                v-for="(s, index) in filterSchedules(locationSchedules)"
                :key="index"
                @click="selectedDate = s"
              >
                <h3>{{ formatDate(s) }}</h3>
              </div>
            </div>
            <hr/>
          </template>
          <div class="flex mt-6">
            <base-button outline @click="close" class="w-48 mr-2">Cancel</base-button>
            <base-button class="w-full" @click="submit(close)">Apply Changes</base-button>
          </div>
        </div>
      </template>
    </modal>
  </span>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { models, useFind, useGet } from 'feathers-vuex'
import { addDays, format, isAfter, parseISO, subDays } from 'date-fns'

import { geocoder, mapboxgl } from '@/services/mapbox'
import Modal from '@/components/Modal'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

export default {
  name: 'SellLogisticModal',
  components: {
    Modal
  },
  props: {
    sell: {
      type: Object,
      required: true
    },
    logistic: {
      type: String,
      required: true
    }
  },
  setup() {
    const { PickupLocation, Postal } = models.api

    const postalCode = ref('')
    const postalId = computed(() => {
      return postalCode.value.length === 5 ? postalCode.value : postalCode.value.substring(0, 3)
    })
    const postalQueryWhen = computed(() => {
      return postalCode.value.length > 0
    })
    const { item: postal, isPending: isPostalPending } = useGet({
      model: Postal,
      id: postalId,
      queryWhen: postalQueryWhen
    })

    const search = ref('')
    const pagination = ref({ $limit: 4, $skip: 0 })
    const fetchParams = computed(() => {
      const query = {
        $or: [
          { id: { $like: '%' + search.value.toUpperCase().trim() + '%' } },
          { name: { $iLike: '%' + search.value.trim() + '%' } }
        ],
        $sort: {
          id: 1
        }
      }
      Object.assign(query, pagination.value)
      return { query, qid: 'pickupLocationParams' }
    })
    const params = computed(() => {
      const query = {
        $or: [
          { id: { $regex: search.value.toUpperCase().trim(), $options: 'i' } },
          { name: { $regex: search.value.trim(), $options: 'i' } }
        ],
        $sort: {
          id: 1
        }
      }
      Object.assign(query, pagination.value)
      return { query, qid: 'pickupLocationParams' }
    })
    const { items: locations } = useFind({ model: PickupLocation, params, fetchParams })

    return {
      postal,
      postalCode,
      isPostalPending,
      search,
      locations
    }
  },
  data() {
    return {
      isOpen: false,
      loading: false,
      map: null,
      marker: null,
      address: null,
      selectedDate: null,
      selectedLocation: null,
      coordinates: [-79.3849, 43.6529],
      zoom: 12
    }
  },
  computed: {
    locationSchedules() {
      return this.selectedLocation?.ancillary?.schedule
        .map(d => format(subDays(parseISO(d), 1), 'yyyy-MM-dd')) || []
    }
  },
  methods: {
    async open() {
      this.isOpen = true
      this.$nextTick(() => {
        if (this.logistic === 'Pickup') {
          this.createMap()
        }
      })
    },
    async createMap() {
      try {
        this.loading = true
        this.map = new mapboxgl.Map({
          container: 'map',
          style: 'mapbox://styles/mapbox/streets-v11',
          center: this.sell.data.region === 'nyc' ? [-74.0060, 40.7128] : [-79.3849, 43.6529],
          zoom: this.zoom
        })
        this.map.on('load', () => {
          geocoder.on('result', (e) => {
            this.marker?.remove()
            this.marker = new mapboxgl.Marker({ color: '#D80739' })
              .setLngLat(e.result.center)
              .addTo(this.map)
            this.coordinates = e.result.center
            this.address = e.result.place_name
            this.postalCode = e.result.place_name.split(', ').pop() === 'United States' ? e.result.place_name.match(/\d{5}/g)[0] : e.result.place_name.match(/[A-Z]\d[A-Z][ -]?\d[A-Z]\d/g)[0]
          })
          this.map.addControl(geocoder)
          this.loading = false
        })
      } catch (err) {
        console.log('map error', err)
      }
    },
    filterSchedules(s) {
      return s.filter(d => isAfter(parseISO(d), new Date()))
        .sort((a, b) => parseISO(a) - parseISO(b))
        .slice(0, 5)
    },
    formatDate(str) {
      return format(parseISO(str), 'eee, MMMM d, yyyy')
    },
    submit(close) {
      const { Sell } = models.api
      const sell = new Sell(this.sell)
      if (this.logistic === 'Pickup') {
        if (!this.address || !this.selectedDate) { return }
        sell.data.coordinates = this.coordinates
        sell.data.address = this.address
        sell.data.logistics.type = this.logistic
        sell.data.logistics.date = this.selectedDate
      }
      if (this.logistic === 'Dropoff') {
        if (!this.selectedLocation || !this.selectedDate) { return }
        sell.data.logistics.type = this.logistic
        sell.data.logistics.dropoffLocationId = this.selectedLocation.id
        sell.data.logistics.date = format(addDays(parseISO(this.selectedDate), 1), 'yyyy-MM-dd')
      }
      sell.save().then(() => {
        this.map = null
        this.marker = null
        this.address = null
        this.selectedDate = null
        this.selectedLocation = null
        this.coordinates = sell.data.region === 'nyc' ? [-74.0060, 40.7128] : [-79.3849, 43.6529]
        close()
      })
    }
  }
}
</script>
