<template>
  <span>
    <slot :open="open">
      <base-button icon-right="plus" @click="open" class="py-1 text-black bg-gray-50 hover:bg-gray-100">Add Product</base-button>
    </slot>
    <modal v-if="isOpen" @close="isOpen = false">
      <template #default="{ close }">
        <div class="py-3 px-5 lg:px-12 lg:py-10">
          <h1>{{ isEditing ? 'Edit Product Details' : 'Add Product' }}</h1>
          <h2 v-show="!isEditing" class="mt-4 text-lg font-bold">Find product from the database</h2>
          <product-picker v-model="productId" :disabled="isEditing"/>
          <base-form-select label="Age" v-model="age">
            <option :value="null" disabled selected hidden>Select Age</option>
            <option v-for="(item, index) in ageToText" :key="index" :value="item.val">{{ item.text }}</option>
          </base-form-select>
          <base-form-select label="Condition" v-model="condition">
            <option :value="null" disabled selected hidden>Select Condition</option>
            <option v-for="(item, index) in conditionToText" :key="index" :value="item.val">{{ item.text }}</option>
          </base-form-select>
          <div class="flex mt-2 mb-6 text-xl">
            <label class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="petFree"/>
              <span class="mr-8 ml-3">Pet Free</span>
            </label>
            <label class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="smokeFree"/>
              <span class="mr-8 ml-3">Smoke Free</span>
            </label>
            <label class="flex items-center">
              <input type="checkbox" class="text-blue-500 checkbox" v-model="cleaningFee"/>
              <span class="mr-8 ml-3">Cleaning Fee</span>
            </label>
          </div>
          <div v-if="isEditing" class="flex gap-2 -mt-6">
            <base-form-select class="w-1/3" label="Currency" v-model="currency">
              <option value="CAD">CAD</option>
              <option value="USD">USD</option>
            </base-form-select>
            <base-form-input class="w-2/3" icon="dollar-sign" label="Quote" v-model="quote"/>
          </div>
          <template v-else>
            <base-form-select label="Currency" v-model="currency">
              <option value="CAD">CAD</option>
              <option value="USD">USD</option>
            </base-form-select>
          </template>
          <hr/>
          <div class="flex gap-2 mt-6">
            <base-button outline @click="close" class="w-48">Cancel</base-button>
            <base-button
              class="w-full bg-blue-500"
              @click="() => isEditing ? save(close) : submit(close)"
              :disabled="!productId || !age || !condition"
            >
              {{ loading ? 'Loading...' : isEditing ? 'Apply Changes' : 'Add Product' }}
            </base-button>
          </div>
        </div>
      </template>
    </modal>
  </span>
</template>

<script>
import { models } from 'feathers-vuex'
import { host } from '../api/client'
import { ageToText, conditionToText, mapToValueText } from '../utils'

import Modal from '@/components/Modal'
import ProductPicker from '@/components/ProductPicker'

export default {
  name: 'SellProductModal',
  props: {
    sell: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      default: null
    },
    isEditing: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Modal,
    ProductPicker
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      productId: null,
      age: null,
      condition: null,
      petFree: false,
      smokeFree: false,
      cleaningFee: false,
      currency: 'CAD',
      quote: null
    }
  },
  computed: {
    ageToText() {
      return mapToValueText(ageToText)
    },
    conditionToText() {
      return mapToValueText(conditionToText)
    }
  },
  methods: {
    open() {
      this.isOpen = true
      if (this.isEditing && this.product) {
        this.populateData()
      }
    },
    populateData() {
      this.productId = this.product.productId
      this.age = this.product.age
      this.condition = this.product.condition
      this.petFree = this.product.petFree
      this.smokeFree = this.product.smokeFree
      if (typeof this.product.cleaningFee !== 'undefined') {
        this.cleaningFee = this.product.cleaningFee
      }
      if (typeof this.product.currency !== 'undefined') {
        this.currency = this.product.currency
      }
      this.quote = this.product.estimate
    },
    async submit(close) {
      if (!this.productId || !this.age || !this.condition) { return }
      this.loading = true
      const { Product, Sell } = models.api
      const product = await Product.get(this.productId)
      if (!product.category) {
        this.loading = false
        this.$store.commit('notify', { title: 'Product does not have category', type: 'alert', timeout: false })
        return
      }
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          product: this.productId,
          age: this.age,
          condition: this.condition,
          category: product.category
        })
      }
      const response = await fetch(`${host}/intel/estimator`, options)
      const data = await response.json()
      const estimate = data.estimate
      if (!estimate) {
        this.loading = false
        return
      }
      const sell = new Sell(this.sell)
      sell.data.products.push({
        productId: this.productId,
        age: this.age,
        condition: this.condition,
        category: product.category,
        estimate,
        currency: this.currency,
        petFree: this.petFree,
        smokeFree: this.smokeFree,
        cleaningFee: this.cleaningFee
      })
      sell.patch().then(() => {
        this.productId = null
        this.age = null
        this.condition = null
        this.petFree = false
        this.smokeFree = false
        this.currency = 'CAD'
        this.cleaningFee = false
        this.loading = false
        close()
      })
    },
    save(close) {
      this.loading = true
      const { Sell } = models.api
      const sell = new Sell(this.sell).clone()
      const idx = sell.data.products.findIndex(p => p.productId === this.product.productId && p.estimate === this.product.estimate)
      const product = sell.data.products[idx]
      product.age = this.age
      product.condition = this.condition
      product.petFree = this.petFree
      product.smokeFree = this.smokeFree
      product.cleaningFee = this.cleaningFee
      product.currency = this.currency
      product.estimate = parseFloat(this.quote).toFixed(2)
      sell.commit()
      sell.patch().then(() => {
        this.loading = false
        close()
      })
    }
  }
}
</script>
