<template>
  <span>
    <icon-button icon="layers" size="20" @click="isOpen = true" class="mr-0.5 lg:hidden"/>
    <base-button icon-left="layers" @click="isOpen = true" class="hidden mr-2 text-black bg-gray-50 hover:bg-gray-100 lg:flex">Merge Request</base-button>
    <modal v-if="isOpen" @close="isOpen = false; selectedSell = null" :width="760">
      <template #default="{ close }">
        <div class="px-5 py-3 lg:px-12 lg:py-10">
          <h1>Merge Sell Request</h1>
          <p class="mt-4 text-lg">Select a Sell Request to be merged into current request.</p>
          <div class="my-6">
            <p v-if="items.length === 0" class="text-gray-500 mb-28">No sell request match merge criteria.</p>
            <div v-for="item in items" :key="item.id" @click="selectedSell = item" class="flex flex-wrap py-4 pl-4 pr-2 mb-3 border rounded-md shadow cursor-pointer lg:mb-2 lg:py-2 lg:pr-4 hover:border-gray-400" :class="[ selectedSell && selectedSell.id === item.id ? 'bg-r-green-200 bg-opacity-20 border-r-green-200' : 'bg-white border-gray-200' ]">
              <div class="flex flex-row-reverse w-full lg:flex-row lg:w-1/2">
                <div class="mr-3 lg:mr-4">
                  <input type="checkbox" class="checkbox" disabled :checked="selectedSell && selectedSell.id === item.id"/>
                </div>
                <div class="mr-auto">
                  <p class="font-bold">{{ `RS-${item.id}` }}</p>
                  <p class="leading-tight">{{ logistic(item) }}</p>
                </div>
              </div>
              <div class="flex-auto w-1/2 my-1 lg:w-1/4 lg:my-0 lg:flex-initial">
                <p>${{ estimates(item) }}</p>
                <p class="text-sm text-gray-500">
                  <span class="inline lg:hidden">Est. Value</span>
                  <span class="hidden lg:inline">Estimated value</span>
                </p>
              </div>
              <div class="flex-auto w-1/2 my-1 lg:w-1/4 lg:my-0 lg:flex-initial">
                <p>
                  {{ item.data.products.length + item.data.customProducts.length }}
                  <span v-if="item.data.customProducts.length" title="Custom Item(s)">({{ item.data.customProducts.length }})</span>
                </p>
                <p class="text-sm text-gray-500">Items <span v-if="item.data.customProducts.length">(custom)</span></p>
              </div>
            </div>
          </div>
          <hr/>
          <div class="flex mt-6">
            <base-button outline @click="close" class="w-48 mr-2">Cancel</base-button>
            <base-button
              class="w-full bg-blue-500"
              @click="submit(close)"
              :disabled="!selectedSell"
            >
              {{ loading ? 'Loading...' : 'Merge Request' }}
            </base-button>
          </div>
        </div>
      </template>
    </modal>
  </span>
</template>

<script>
import { computed } from '@vue/composition-api'
import { models, useFind } from 'feathers-vuex'

import feathersClient from '../api/client'
import Modal from '@/components/Modal'

export default {
  name: 'SellMergeModal',
  props: {
    sell: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal
  },
  setup(props) {
    const { Sell } = models.api

    const params = computed(() => {
      const query = {
        id: { $ne: props.sell.id },
        status: 'nc',
        'data.email': props.sell.data.email,
        $limit: 10,
        $skip: 0
      }
      return { query }
    })

    const { items, isPending } = useFind({ model: Sell, params })

    return {
      items,
      isPending
    }
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      selectedSell: null
    }
  },
  methods: {
    estimates(item) {
      return item.data.products.reduce((sum, p) => parseFloat(parseFloat(sum) + parseFloat(p.estimate)).toFixed(2), '0.00')
    },
    logistic(item) {
      return item.data.logistics.type === 'Unset'
        ? 'Pickup / Drop-off'
        : item.data.logistics.type === 'Dropoff'
          ? 'Drop-off'
          : item.data.logistics.type
    },
    async submit(close) {
      if (this.selectedSell === null) { return }
      this.loading = true
      const { Sell } = models.api
      const sell = new Sell(this.sell).clone()
      sell.data.products = [...sell.data.products, ...this.selectedSell.data.products]
      sell.data.customProducts = [...sell.data.customProducts, ...this.selectedSell.data.customProducts]
      await sell.commit()
      await sell.save()

      for await (const product of this.selectedSell.data.products) {
        await feathersClient.service('sell/interface').patch(product.interfaceId, { sellRef: this.sell.id })
      }

      await this.selectedSell.remove()
      this.$store.commit('notify', { type: 'success', title: 'Sell Request successfully merged' })
      this.loading = false
      close()
    }
  }
}
</script>
