<template>
  <span class="ml-2" title="Change Date">
    <icon-button icon="calendar" size="16" @click="isOpen = true" class="w-6 h-6"/>
    <modal v-if="isOpen" @close="isOpen = false">
      <template #default="{ close }">
        <div class="py-3 px-5 lg:px-12 lg:py-10">
          <h1 class="mb-6">Change {{ sell.data.logistics.type === 'Dropoff' ? 'Drop-off' : sell.data.logistics.type }} Date</h1>
          <template v-if="sell.data.logistics.type === 'Pickup'">
            <feathers-vuex-get
              v-slot="{ item: postal }"
              service="postal"
              :id="sell.data.postal.substring(0, 3)"
            >
              <div v-if="postal">
                <v-calendar
                  is-expanded
                  title-position="left"
                  color="green"
                  :attributes="attributes"
                  :available-dates="availableDates(postal.ancillary.schedule)"
                  @dayclick="onDayClick"
                />
              </div>
            </feathers-vuex-get>
          </template>
          <template v-if="sell.data.logistics.type === 'Dropoff'">
            <feathers-vuex-get
              v-slot="{ item: location }"
              service="pickup-locations"
              :id="sell.data.logistics.dropoffLocationId"
            >
              <div v-if="location">
                <v-calendar
                  is-expanded
                  title-position="left"
                  color="green"
                  :attributes="attributes"
                  :available-dates="availableDates(location.ancillary.schedule, location.ancillary.hours)"
                  @dayclick="onDayClick"
                />
                <div class="flex flex-wrap mt-3">
                  <p class="flex items-center mr-8 text-gray-700">
                    <span class="inline-block mr-2 w-4 h-4 bg-gray-400 rounded-lg"></span>
                    <span>Customer Drop-off Date</span>
                  </p>
                  <p class="flex items-center mr-8 text-gray-700">
                    <span class="inline-block mr-2 w-4 h-4 bg-green-500 rounded-lg"></span>
                    <span>Logistic Pickup Date</span>
                  </p>
                </div>
              </div>
            </feathers-vuex-get>
          </template>
          <div class="flex mt-6">
            <base-button outline @click="close" class="mr-2 w-48">Cancel</base-button>
            <base-button class="w-full bg-blue-500" @click="save(close)">
              {{ loading ? 'Loading...' : 'Apply Changes' }}
            </base-button>
          </div>
        </div>
      </template>
    </modal>
  </span>
</template>

<script>
import { FeathersVuexGet, models } from 'feathers-vuex'
import { getDay, parseISO, subDays } from 'date-fns'
import Calendar from 'v-calendar/lib/components/calendar.umd'
import Modal from '@/components/Modal'

export default {
  name: 'SellTimeModal',
  props: {
    sell: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal,
    FeathersVuexGet,
    'v-calendar': Calendar
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      newDate: this.sell.data.logistics.date
    }
  },
  computed: {
    attributes() {
      const attr = [
        {
          key: 'current',
          highlight: {
            style: {
              backgroundColor: '#10B981'
            },
            contentStyle: {
              color: 'white',
              fontWeight: 'bold'
            }
          },
          dates: parseISO(this.newDate)
        },
        {
          key: 'today',
          dot: 'red',
          dates: new Date()
        }
      ]
      if (this.sell.data.logistics.type === 'Dropoff') {
        attr.push({
          key: 'dropoff',
          highlight: {
            style: {
              backgroundColor: '#9CA3AF'
            },
            contentStyle: {
              color: 'white',
              fontWeight: 'bold'
            }
          },
          dates: subDays(parseISO(this.newDate), 1)
        })
      }
      return attr
    },
    availableDates() {
      return (arr, hours = []) => {
        const dates = arr.map(d => parseISO(d))
        if (this.sell.data.logistics.type === 'Dropoff') {
          return dates.filter(d => hours.map(h => h.day).includes(getDay(subDays(d, 1))))
        }
        return dates
      }
    }
  },
  methods: {
    onDayClick(day) {
      if (day.isDisabled) { return }
      this.newDate = day.id
    },
    save(close) {
      this.loading = true
      const { Sell } = models.api
      const sell = new Sell(this.sell)
      sell.data.logistics.date = this.newDate
      sell.save().then(() => {
        this.loading = false
        close()
      })
    }
  }
}
</script>
